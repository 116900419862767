<template>
  <v-parallax class="login-img" :src="url + '/asset/images/login.jpg'" >
    <v-row justify="center">
      <v-col cols="12" xs="10" md="4">
        <v-card class="mx-auto glass" :loading="loading" :disabled="loading" :elevation="16">
          <v-card-title>
            <v-row>
              <v-col cols="12" xs="12" md="12" class="text-center">
                <h1 class="text-h4 text--primary" v-text="page_title" />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" xs="12" md="12">
                <v-form ref="form_data" lazy-validation>
                  <v-text-field
                    label="Correo electrónico*"
                    type="email"
                    v-model="data.email"
                    :rules="rules.email"
                    :disabled="loading"
                    required
                    prepend-icon="mdi-at"
                  />
                  <v-text-field
                    label="Contraseña*"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="data.password"
                    :rules="rules.password"
                    :disabled="loading"
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    prepend-icon="mdi-lock"
                  />
                  <v-col cols="12" xs="12" md="6" offset-md="3">
                    <p class="text-center"><strong>{{message}}</strong></p>
                  </v-col>
                  <div class="text-center">
                    <v-btn
                      v-text="'Entrar'"
                      color="info"
                      size="small"
                      class="glass"
                      @click="auth"
                    />
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import { URL_API } from '../control'
import { mapActions } from 'vuex'
export default {
  created () {
    this.getDay()
    this.url = URL_API
  },
  data () {
    return {
      page_title: 'Iniciar Sesión',
      loading: false,
      day: 1,
      showPassword: false,
      data: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          (v) => !!v || 'Campo requerido',
          (v) => (v && v.length <= 65) || 'Máximo 65 caracteres'
        ],
        password: [
          (v) => !!v || 'Campo requerido',
          (v) => (v && v.length <= 45) || 'Máximo 45 caracteres'
        ]
      },
      message: ''
    }
  },
  methods: {
    ...mapActions({
      logIn: 'auth/logIn'
    }),
    auth () {
      this.logIn(this.data).then((response) => {
        if (response.data.auth) {
          this.$router.push({
            name: 'admin.home'
          })
        } else {
          this.message = response.data.message
        }
      })
    },
    getDay () {
      var today = new Date()
      this.day = today.getDate()
    }
  }
}
</script>
